@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "font-size": map-merge(map-get($utilities, "font-size"),
      (responsive: true),
    ),
  )
);


@import "bootstrap/scss/utilities/api";
// @import 'bootstrap/scss/bootstrap';

// ALL CONSTANT VARIABLES 
$informa-green: #F26E33;

// ALL STYLES 
.loader .spinner>div {
  background-color: rgba($color:$informa-green, $alpha: 0.1);
}